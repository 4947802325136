import React from 'react'
import { graphql , useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import SEO from '../components/seo'
// import { StaticImage } from 'gatsby-plugin-image'
import SearchBar from '../components/search'




const Blog = () => {
  const data = useStaticQuery(graphql`
  query{
    allMdx(sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          description
          category
          image
        }
        id
        slug
      }
    }
    siteSearchIndex {
      index
    }
  }
  

`)

  return (

      <Layout>
      <SEO title="Blogs" description='This is blog page' />
  
      <h1>BLOGS&nbsp;</h1>
      <Link  className="btn btn-primary px-4 lead btn-space" to='/search'>SEO</Link>
      <Link  className="btn btn-secondary px-4 lead btn-space" to='/developer'>DEV</Link>
      <Link  className="btn btn-success px-4 lead btn-space" to='/machine'>MACHINE</Link>
     

      <SearchBar  searchIndex={data.siteSearchIndex.index} />
      
      {
     data.allMdx.nodes.map(node => (
  
      <article key={node.id}>
      <div className="row mb-2">
      <div className="col">
        <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
          <div className="col p-4 d-flex flex-column position-static">
            <strong className="d-inline-block mb-2 text-primary">{node.frontmatter.category}</strong>
            <h3 className="mb-0">{node.frontmatter.title}</h3>
            <div className="mb-1 text-muted">{node.frontmatter.date}</div>
            <p className="card-text mb-auto">{node.frontmatter.description}</p>
            <Link to={`/blog/${node.slug}`}>
            Continue reading</Link>
          </div>
          <div className="col-auto d-none d-lg-block">
         <div className='imageblog'>
         <img
        src={`../../${node.frontmatter.image}`} />
        </div>
  
          </div>
      </div>
    </div>
    </div>
    </article>

    ))
      }
  
      
 
  
      
        
      </Layout>
  )
}

export default Blog